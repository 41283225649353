import NewHeader from "../components/NewHeader_dev";
import BoardCSS from "./Board_dev.module.css";
import Footer from "../components/Footer";
import Loader from '../components/Loader'
import React,{useState,useEffect} from 'react'

function Board() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  return (
    <div className={BoardCSS.content}>
      <NewHeader />
      <br />
      {loading? <Loader/> : 
      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      <Footer />
      </div>}
      
    </div>
  );
}
export default Board;
