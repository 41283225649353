import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./routes/Home";
import Product from "./routes/Product";
import AboutUs from "./routes/AboutUs";
import Download from "./routes/Download";
import SmartoothApp from "./routes/SmartoothApp";
import Premium from "./routes/Premium";
import Video from "./routes/Video";
import Home_ver1 from "./routes/Home_ver1";
import Product_ver1 from "./routes/Product_ver1";
import AboutUs_ver1 from "./routes/AboutUs_ver1";
import Home_dev from "./routes/Home_dev";
import Product_dev from "./routes/Product_dev";
import AboutUs_dev from "./routes/AboutUs_dev";
import Board_dev from "./routes/Board_dev";
// import {Helmet} from './react-helmet'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="product" element={<Product />} />
        <Route path="about_us" element={<AboutUs />} />
        <Route path="/" element={<Home />} />
        <Route path="download" element={<Download />} />
        <Route path="smartoothapp" element={<SmartoothApp />} />
        <Route path="premium" element={<Premium />} />
        <Route path="video" element={<Video />} />
        <Route path="Home_ver1" element={<Home_ver1 />} />
        <Route path="Product_ver1" element={<Product_ver1 />} />
        <Route path="AboutUs_ver1" element={<AboutUs_ver1 />} />
        <Route path="Home_dev" element={<Home_dev />} />
        <Route path="Product_dev" element={<Product_dev />} />
        <Route path="AboutUs_dev" element={<AboutUs_dev />} />
        <Route path="Board_dev" element={<Board_dev />} />
      </Routes>
    </Router>
  );
}

export default App;
