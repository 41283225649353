import React from "react";
import MainVannerCSS from "./MainVanner_dev.module.css";
import { Link } from "react-router-dom";

function MainVanner() {
  return (
    <div className={MainVannerCSS.baseStart}>
      <img className={MainVannerCSS.back} src="img/main_Dev.png" alt="main" />
    </div>
  );
}
export default MainVanner;
